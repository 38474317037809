.homeNewsNav {
  &__items { }

  &__item {
    margin-bottom: 10px;

    @media (--sm) {
      margin-bottom: 5px;
    }
  }

  &__lnk {
    display: block;

    @media (--sm) {
      display: flex;
      align-items: center;
    }
  }

  &__date {
    font-weight: bold;
    color: var(--color-textgray);

    @media (--sm-max) {
      display: block;
      font-size: 1.4rem;
      margin-bottom: 3px;
    }

    @media (--sm) {
      width: 120px;
    }
  }

  &__ttl {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (--sm-max) {
      font-size: 1.4rem;
    }

    @media (--sm) {
      width: calc(100% - 85px);
    }
  }
}
