.homeEyecatch {
  width: 100%;
  margin-bottom: 24px;

  @media (--sm) {
    margin-bottom: 135px;
  }
}

.homeAbout {
  margin-bottom: 52px;

  @media (--sm) {
    margin-bottom: 162px;
  }

  @media (--sm-max) {
    padding: 30px 25px 0;
  }

  &-title {
     width: 76px;
     margin-bottom: 20px;
    @media (--sm) {
      width: 126px;
      margin: 0 auto 53px;
    }
   }

  &-table {
     width: 100%;
   table-layout: fixed;
   border-spacing: 0;
   border-collapse: separate;

   @media (--sm) {
     width: 640px;
   }
   &__wrap {
      @media (--sm-max) {
        padding: 0 15px;
      }
    }
  &__tr {
    @media (--sm-max) {
      display: block;
    }
   }

    &__th, &__td {
      line-height: 1.6;
      @media (--sm-max) {
        display: block;
      }
      @media (--sm) {
        line-height: 2;
        padding-top: 38px;
      }
    }
    &__th {
      font-size: 1.3rem;
      vertical-align: top;
       text-align: left;
      @media (--sm) {
        width: 119px;
        text-align: right;
        font-size: 1.5rem;
        letter-spacing: 4px;
      }
      @media (--sm-max) {
        padding-top: 19px;
      }
    }
    &__td {
      font-size: 1.3rem;
      letter-spacing: 1px;
      @media (--sm) {
        font-size: 1.6rem;
        letter-spacing: 2.7px;
        padding-left: 65px;
      }
      @media (--sm-max) {
        padding-top: 5px;
      }
    }
}
}

.homeProduct {
    width: 100%;
    background-color: #FFE6DC;
    padding: 50px 25px 38px;
  @media (--sm) {
    padding: 50px 80px 50px;
  }
  &-card {
    &__img {
      margin-bottom: 20px;
      @media (--sm) {
        margin-bottom: 14px;
      }
    }
    &__title {
      font-size: 1.8rem;
      letter-spacing: 1px;
      margin-bottom: 15px;
      @media (--sm) {
        font-size: 2.4rem;
        margin-bottom: 3px;
      }
    }

 &__text {
  font-size: 1.3rem;
  letter-spacing: 1px;
  line-height: 1.7;
  @media (--sm) {
    font-size: 1.6rem;
    line-height: 2;
  }
  }
   }
}

.lowerNav {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    &:first-child {
      margin-bottom: 65px;

      @media (--sm) {
        margin-bottom: 130px;
      }
    }
  }
  &__lnk {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;

    @media (--sm) {
      font-size: 3.0rem;
    }

    i {
      font-size: 2.0rem;
      margin-left: 13px;

      @media (--sm) {
        font-size: 4.0rem;
      }
    }
  }
}

.home {
  &Sec {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.-stylecalc {
      margin-bottom: 49px;

      @media (--sm) {
        padding: 0 0 30px;
        margin-bottom: 155px;
      }

      .homeBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 70px;

        @media (--sm) {
          margin-bottom: 190px;
        }

        .btn {
          width: 275px;

          @media (--sm) {
            width: 400px;
          }

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }

    &.-contact {
      background-color: var(--color-contactBgGray);
      padding: 52px 25px 5px;

      @media (--sm) {
        background-color: var(--color-contactBgGray);
        padding: 60px 20px 30px;
      }

      .secHdr {
        margin-bottom: 5px;

        @media (--sm) {
          margin-bottom: 43px;
        }

        &__ttl {
          width: 105px;
          margin: 0 0 18px 0;

          @media (--sm) {
            width: 155px;
            margin: 0 0 25px 5px;
          }
        }
      }

      .contactFrm {
        width: 100%;
        max-width: 800px;
      }
    }
  }

  &NewsNav {
    width: 100%;
    max-width: 700px;

    @media (--sm) {
      width: calc(100% - 30px);
    }
  }
}

