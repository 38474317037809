@define-mixin txtColors {
  &.-color_black {
    color: var(--color-black);
  }

  &.-color_gray {
    color: var(--color-gray)
  }

  &.-color_lightgray {
    color: var(--color-lightgray)
  }

  &.-color_red {
    color: var(--color-red);
  }

}

