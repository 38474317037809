.post {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__hdr {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    @media (--sm) {
      padding-bottom: 35px;
      margin-bottom: 100px;
      border-bottom: solid 1px var(--color-bdr-gray);
    }

    &In {
      width: calc(100% - 20px);
      max-width: 800px;

      @media (--sm) {
        width: calc(100% - 50px);
      }
    }

    &Date {
      color: var(--color-textgray);
      font-weight: bold;
      margin-bottom: 2px;

      @media (--sm) {
        margin-bottom: 5px;
      }
    }

    &Ttl {
      font-weight: bold;

      @media (--sm) {
        font-size: 3.0rem;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 55px;

    @media (--sm) {
      width: calc(100% - 50px);
      max-width: 800px;
      margin-bottom: 115px;
    }
  }

  &__media {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;

    @media (--sm) {
      margin-bottom: 95px;
    }

    &Img {
      width: calc(100% - 100px);

      @media (--xs-max) {
        width: 100%;
        max-width: inherit;
      }

      @media (--sm) {
        max-width: 600px;
      }
    }
  }

  &__cont {
    width: calc(100% - 20px);
    font-size: 1.4rem;
    margin-bottom: 50px;

    .p1 {
      margin-bottom: 15px;
      a {
        color: var(--color-orange);
      }
    }
  }

  &__returnBtn {
    width: calc(100% - 50px);
    height: 50px;
    max-width: 400px;
  }
}
