.contactFrm {
  &__fldset {
    border: none;
  }

  &__grp {
    margin-bottom: 16px;

    @media (--sm) {
      display: flex;
      align-items: center;
      margin-bottom: 31px;
    }

    .wpcf7-form-control-wrap {
      display: block;

      @media (--sm) {
        width: calc(100% - 180px);
      }
    }
  }

  &__lbl {
    @media (--sm-max) {
      display: block;
      font-size: 1.4rem;
      margin-bottom: 3px;
    }

    @media (--sm) {
      width: 180px;
    }

    &.-require {
      &::after {
        content: "*";
        font-size: 2.0rem;
        color: var(--color-red);
        margin-left: 3px;
      }
    }

    &.-align_top {
      align-self: flex-start;
      margin-top: 10px;
    }
  }

  &__input {
    width: 100%;
    height: 40px;
    border: solid 1px var(--color-darkgray);
    font-size: inherit;
    border-radius: 3px;
    padding: 0 10px;

    @media (--sm) {
      height: 50px;
      padding: 0 20px;
    }
  }

  &__textarea {
    width: 100%;
    height: 175px;
    border: solid 1px var(--color-darkgray);
    font-size: inherit;
    border-radius: 3px;
    padding: 10px;

    @media (--sm) {
      height: 350px;
      padding: 15px;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;

    @media (--sm-max) {
      flex-direction: column;
    }
  }

  &__chk {
    display: block;
    margin-right: 10px;

    @media (--sm) {
      margin-right: 20px;
    }

    &Wrp {
      display: flex;

      @media (--sm-max) {
        margin-bottom: 15px;
      }
    }

    &Txt {

    }

    input {
      width: 24px;
      height: 24px;
      appearance: none;
      border: solid 1px var(--color-darkgray);
      background-color: var(--color-white);
      position: relative;
      outline: none;
      cursor: pointer;
      border-radius: 3px;
      margin: 0;

      @media (--sm) {
        width: 30px;
        height: 30px;
      }

      &::after {
        content: "";
        width: 24px;
        height: 24px;
        display: block;
        background: url(/assets/img/icon--chk.svg) no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 0;
        bottom: 0;
        pointer-events: none;
        visibility: hidden;
        transform: scale(0.8);
        opacity: 0;
        transition: 0.3s;

        @media (--sm) {
          width: 30px;
          height: 30px;
        }
      }

      &:checked::after {
        visibility: visible;
        opacity: 1;
      }
    }

    span.wpcf7-list-item {
      margin: 0;
    }
  }


  &__submit {
    width: 150px;

    @media (--sm) {
      width: 200px;
    }

    &Wrp {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (--sm-max) {
        align-self: center;
      }

      @media (--sm) {
        margin-left: auto;
      }
    }
  }

  div.wpcf7 .ajax-loader {
    margin-top: 30px;
  }
  div.wpcf7-response-output {
    border: 2px solid #ff0000;
  }

  div.wpcf7-mail-sent-ok {
    border: 2px solid #53c3f1;
  }

  div.wpcf7-mail-sent-ng,
  div.wpcf7-aborted {
    border: 2px solid #ff0000;
  }

  div.wpcf7-spam-blocked {
    border: 2px solid #ffa500;
  }

  div.wpcf7-validation-errors,
  div.wpcf7-acceptance-missing {
    border: 2px solid #f7e700;
  }
}
