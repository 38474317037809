.btn,
a.btn,
button.btn {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-size: 1.4rem;
  font-weight: bold;
  white-space: nowrap;
  -webkit-appearance: none;
  border: none;
  position: relative;

  @media (--sm) {
    font-size: 1.5rem;
  }

  @mixin outlineColors;
  @mixin borderRadiuses;

  &.-bold {
    font-weight: bold;
  }

  &__ico {
    position: absolute;
    top: 50%;
    font-size: 2.4rem;
    transform: translateY(-50%);

    &.-pos_lft {
      left: 10px;
    }

    &.-pos_rgt {
      right: 10px;
    }
  }

  &.-fill_red {
    background-color: var(--color-red);
  }
}

