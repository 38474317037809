.newsNav {
  &__items {
    border-top: solid 1px var(--color-darkgray);
  }

  &__item {
    margin-bottom: 5px;
    border-bottom: solid 1px var(--color-darkgray);
  }

  &__lnk {
    display: flex;
    justify-content: space-between;
    padding: 18px 0;

    @media (--sm) {
      padding: 40px 0;
    }
  }

  &__eyecatch {
    width: 100px;

    @media (--sm) {
      width: 120px;
    }
  }

  &__cont {
    width: calc(100% - 120px);

    @media (--sm) {
      width: calc(100% - 155px);
      padding-top: 15px;
    }

    &Date {
      display: block;
      color: var(--color-textgray);
      font-weight: bold;
      margin-bottom: 0;

      @media (--sm-max) {
        font-size: 1.4rem;
      }

      @media (--sm) {
        margin-bottom: 5px;
      }
    }

    &Ttl {
      @media (--sm-max) {
        font-size: 1.4rem;
      }
    }
  }
}
