.snsNav {
  &__items {
    display: flex;
    align-items: center;
  }

  &__item {
    &:first-child {
      margin-right: 10px;
    }
  }

  &__lnk {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: var(--color-lightgray);
    border-radius: 20px;

    i {
      color: white;
      position: relative;
    }

    .af-twitter {
      font-size: 1.7rem;
    }

    .af-instagram {
      font-size: 2.3rem;
    }
  }
}
