.shareNav {
  position: relative;
  padding-top: 35px;

  @media (--sm-max) {
    transform: scale(0.8);
  }

  &__items {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 15px;
    }
  }

  &__lnk {
    width: 65px;
    display: block;
  }

  &__img {
    width: 100%;
  }

  &__txt {
    width: 150px;
    position: absolute;
    top: 0;
    right: 0;

    &Img {
      width: 100%;
    }
  }
}
