@define-mixin outlineColor $color, $textColor: $color {
  &:not(.-outline) {
    background-color: $color;
  }

  &.-outline {
    border: solid 1px $color;
    color: $textColor;
  }
}

@define-mixin outlineColors {
  &.-color_black {
    @mixin outlineColor var(--color-black);
  }

  &.-color_gray {
    @mixin outlineColor var(--color-gray), var(--color-text);
  }

  &.-color_darkgray {
    @mixin outlineColor var(--color-darkgray), var(--color-text);
  }


  &.-color_red {
    @mixin outlineColor var(--color-red);
  }
}
