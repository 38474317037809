@font-face {
  font-family: 'appforet';
  src:  url('../fonts/appforet.eot?4kdhva');
  src:  url('../fonts/appforet.eot?4kdhva#iefix') format('embedded-opentype'),
    url('../fonts/appforet.ttf?4kdhva') format('truetype'),
    url('../fonts/appforet.woff?4kdhva') format('woff'),
    url('../fonts/appforet.svg?4kdhva#appforet') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="af-"], [class*=" af-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'appforet' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @mixin txtColors;
}

.af-instagram:before {
  content: "\e905";
}
.af-twitter:before {
  content: "\e906";
}
.af-arrow--right:before {
  content: "\e900";
}
.af-chevron--left:before {
  content: "\e901";
}
.af-chevron--right:before {
  content: "\e902";
}
.af-chevron--up:before {
  content: "\e903";
}
.af-close:before {
  content: "\e904";
}
