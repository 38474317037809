.glbDrawerNav {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 5px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 101;
  visibility: hidden;
  opacity: 0;

  @media (--sm) {
    top: 45px;
  }

  body.is-opened--drawerNav & {
    animation: fadeIn 0.5s forwards;
  }

  body.is-closed--drawerNav & {
    animation: fadeOut 0.5s forwards;
  }

  &__items {
    width: calc(100% - 100px);
    top: 95px;
    transform: scale(0.8);

    @media (--sm) {
      top: 100px;
    }
  }

  &__item {
    display: flex;
    height: 100px;
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;

    @media (--sm) {
      height: 100px;
    }

    body.is-closed--drawerNav & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      animation: fadeOut 0.5s forwards;
    }

    body.is-opened--drawerNav & {
      &:nth-of-type(1) {
        animation:
          translateY20In 0.5s ease 0.35s forwards,
          fadeIn 0.5s ease 0.35s forwards;
      }

      &:nth-of-type(2) {
        animation:
          translateY20In 0.5s ease 0.3s forwards,
          fadeIn 0.5s ease 0.3s forwards;
      }

      &:nth-of-type(3) {
        animation:
          translateY20In 0.5s ease 0.25s forwards,
          fadeIn 0.5s ease 0.25s forwards;
      }

      &:nth-of-type(4) {
        animation:
          translateY20In 0.5s ease 0.2s forwards,
          fadeIn 0.5s ease 0.2s forwards;
      }

      &:nth-of-type(5) {
        animation:
          translateY20In 0.5s ease 0.15s forwards,
          fadeIn 0.5s ease 0.15s forwards;
      }
      &:nth-of-type(6) {
        animation:
          translateY20In 0.5s ease 0.1s forwards,
          fadeIn 0.5s ease 0s forwards;
      }
    }
  }

  &__lnk {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img {
    width: 165px;

    @media (--sm) {
      width: 165px;
    }
  }
}
