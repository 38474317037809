.c-styleIcon {
  &.-chevronCircleRight {
    display: block;
    width: 45px;
    height: 45px;
    position: relative;
    border: solid 1px var(--color-darkGray);
    border-radius: 100%;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 11px;
      background: var(--color-darkGray);
    }

    &::before {
      transform: rotate(27deg);
      margin-top: -4px;
    }

    &::after {
      transform: rotate(-27deg);
      margin-top: 5px;
    }
  }
}

