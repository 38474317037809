.c-productGrid {
  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item { }

  &.-layout_col2 &__item {
    margin-bottom: 50px;

    @media (--sm-max) {
      width: calc((100% - (15px * 1)) / 2);

      &:not(:nth-of-type(2n)) {
        margin-right: 15px;
      }
    }

    @media (--sm-md) {
      width: calc((100% - (25px * 3)) / 4);

      &:not(:nth-of-type(4n)) {
        margin-right: 25px;
      }
    }

    @media (--md-lg) {
      width: calc((100% - (25px * 1)) / 2);

      &:not(:nth-of-type(2n)) {
        margin-right: 25px;
      }
    }

    @media (--lg) {
      width: calc((100% - (25px * 3)) / 4);

      &:not(:nth-of-type(4n)) {
        margin-right: 25px;
      }
    }
  }
}

