.container {
  max-width: 1000px;

  &:not(.-fluid) {
    width: calc(100% - 50px);
  }

  &.-fluid {
    width: 100%;
  }
}
