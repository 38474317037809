.glbHdr {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--color-white);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);

  &__in {
    width: 100%;
    max-width: 1000px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 321px) {
      justify-content: space-between;
    }
    @media (--sm) {
      height: 100px;
    }
  }

  & &Logo {
    width: 270px;
    opacity: 1;
    transition: all 0.3s;

    @media (min-width: 321px) {
      width: 310px;
      margin-left: 20px;
    }
    @media (--sm-max) {
      margin-top: 10px;
    }

    @media (--sm) {
      width: 590px;
      margin-left: 24px;
    }
  }

  .snsNav {
    margin-left: auto;
    display: none;
    margin-right: 10px;

    @media (--sm) {
      display: block;
    }
  }
}


