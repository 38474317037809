.newsDet {
  padding-top: 30px;

  @media (--sm) {
    padding-top: 50px;
  }

  &Sec { }

  .secHdr {
    margin-bottom: 26px;

    @media (--sm) {
      margin-bottom: 50px;
    }

    &__ttl {
      width: 132px;

      @media (--sm) {
        width: 165px;
      }
    }
  }
}
