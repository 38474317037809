.secHdr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &.-row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__ttlGrp {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }

  &__ttl {
    &.-news {
      width: 72px;

      @media (--sm) {
        width: 100px;
      }
    }

    &.-works {
      width: 92px;

      @media (--sm) {
        width: 125px;
      }
    }

    &.-company {
      width: 112px;

      @media (--sm) {
        /*width: 149px;*/
        width: 165px;
      }
    }

    &.-contact {
      width: 92px;
      margin: 0 0 18px 0;

      @media (--sm) {
        width: 122px;
        margin: 0 0 25px 19px;
      }
    }

    &Img {}
  }

  &__lnk {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @media (--sm-max) {
      font-size: 1.4rem;
    }

    &Ico {
      font-size: 2.5rem;
      margin-left: 5px;

      @media (--sm) {
        font-size: 3.0rem;
        margin-left: 10px;
      }
    }
  }

  &__desc {
    width: 100%;
    text-align: center;

    @media (--sm-max) {
      font-size: 1.4rem;
    }
  }

  &__annot {
    @media (--sm-max) {
      font-size: 1.4rem;
    }
  }
}
