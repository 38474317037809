.snsNav {
  &__items {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &__lnk {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    color: var(--color-darkgray);
    line-height: 1;
    border-radius: 50%;

    .af-twitter {
      font-size: 1.8rem;
    }

    .af-instagram {
      font-size: 2.6rem;
    }
  }
}

.mainvis {
  width: 100%;
  max-width: 1000px;
  position: relative;

  .snsNav {
    position: absolute;
    top: 20px;
    left: 20px;

    @media (--md-max) {
      display: none;
    }
  }

  &__logo {
    width: 305px;
    position: absolute;
    left: 17px;
    bottom:13px;

    @media (--md-max) {
      display: none;
    }
  }

  &.swiper-container {
    padding-bottom: 40px;

    @media (--md) {
      padding-bottom: 45px;
    }
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  &.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0px;
  }

  .swiper-pagination-bullet-active {
    background-color: var(--color-green);
  }

  &.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px;

    @media (--md) {
      margin: 0 10px;
    }
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;

    @media (--md) {
      width: 10px;
      height: 10px;
    }
  }
}

.kumaTMainvis {
  position: relative;

  &__bg { }

  &__logo {
    width: 305px;
    position: absolute;
    left: 17px;
    bottom:13px;

    @media (--md-max) {
      display: none;
    }
  }

  &__pnl {
    width: 240px;
    height: 490px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 21px 0 0;
    background: url(/assets/img/home/goodsPnl__bg.png) center center;
    background-size: cover;

    @media (--md-max) {
      display: none;
    }

    &Ttl {
      width: 167px;
      margin: 0 0 14px 4px;
      /* 25 */

      &Img {
        width: 100%;
      }
    }

    &Desc {
      letter-spacing: -1.2px;
      margin-left: 5px;
      margin-bottom: 7px;
      /* 25 */
    }

    &Img {
      width: 210px;
      margin-left: -15px;
    }

    &Btn {
      width: 200px;
      margin-top: 10px;

      @media (--md) {
        display: none;
      }
    }
  }

  .snsNav {
    @media (--md-max) {
      display: none;
    }
  }

  &__btn {
    width: 100px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    &Img {
      width: 100%;
    }
  }
}

