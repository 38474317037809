.pageUpBtn {
  position: fixed;
  right: 20px;
  bottom: 40px;
  font-size: 6.5rem;
  line-height: 1;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;

  body.state-pageUp-active & {
    visibility: visible;
    opacity: 1;
  }

  &::before {
    content: "";
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--color-white);
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  @media (--sm) {
    font-size: 8.0rem;
  }

  &__ico {
    position: relative;
    z-index: 10;
  }
}
