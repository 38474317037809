.glbHdrHamburger {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (--sm) {
    width: 50px;
  }

  &__line {
    width: calc(100% - 27px);
    height: 2px;
    background-color: var(--color-textgray);
    position: relative;
    border-radius: 3px;
    transition: all 0.3s;

    @media (--sm) {
      height: 3px;
      width: calc(100% - 18px);
    }

    body.is-opened--drawerNav & {
      background-color: transparent;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: var(--color-textgray);
      border-radius: 3px;
      transition: 0.3s;

      @media (--sm) {
        height: 3px;
      }
    }

    &::before {
      top: -10px;

      body.is-opened--drawerNav & {
        top: 0;
        transform: rotate(225deg);
      }

      @media (--sm) {
        top: -15px;
      }
    }

    &::after {
      top: 10px;

      body.is-opened--drawerNav & {
        top: 0;
        transform: rotate(-225deg);
      }

      @media (--sm) {
        top: 15px;
      }
    }

  }

}
