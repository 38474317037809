.c-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  white-space: nowrap;

  @mixin outlineColors;
  @mixin borderRadiuses;

  &.-new {
    @mixin outlineColor var(--color-red);
  }

  &.-time {
    @mixin outlineColor var(--color-black);
  }
}

