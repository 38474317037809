.c-companyTbl {
  table-layout: fixed;

  &__tr {
    @media (--sm-max) {
      display: block;
      margin-bottom: 26px;
    }
  }

  &__th,
  &__td {
    font-size: 1.8rem;
    letter-spacing: 3.0px;

    @media (--sm-max) {
      font-size: 1.4rem;
      display: block;
    }

    @media (--sm) {
      padding: 22px 0;
    }
  }

  &__th {
    vertical-align: top;
    font-weight: bold;
    text-align: left;

    @media (--sm) {
      width: 180px;
    }
  }

  &__td {
  }

  &__member {
    &Items {

    }

    &Item {
      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }
  }

}

