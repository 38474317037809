:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #464646;
  --color-lightgray: #969696;
  --color-darkgray: #afafaf;
  --color-red: #7e1000;
  --color-text: #333;
  --color-textgray: #969696;
  --color-contactBgGray: #f5f5f5;
}

