.news {
  padding-top: 30px;

  @media (--sm) {
    padding-top: 50px;
  }

  &Sec { }

  .secHdr {
    margin-bottom: 16px;

    @media (--sm) {
      margin-bottom: 24px;
    }

    &__ttl {
      width: 132px;

      @media (--sm) {
        width: 165px;
      }
    }
  }

  &Desc {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;

    @media (--sm-max) {
      font-size: 1.4rem;
    }
  }
}
