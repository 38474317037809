.c-workCard {
  padding: 50px 25px;

  @media (--sm) {
    padding: 50px 100px;
  }

  &.-kuma {
    background-color: var(--color-lightyellow);
  }

  &.-cutecalc {
    background-color: var(--color-lightpink);
  }

  &__media {
    margin-bottom: 19px;

    @media (--sm) {
      margin-bottom: 15px;
    }
    &Img {}
  }

  &__ttl {
    font-size: 1.8rem;
    margin-bottom: 15px;

    @media (--sm) {
      font-size: 2.3rem;
      margin-bottom: 8px;
    }
  }

  &__desc {
    margin-bottom: 20px;

    @media (--sm-max) {
      font-size: 1.4rem;
    }

    @media (--sm) {
      margin-bottom: 25px;
    }
  }

  &__nav {
    &Items {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &Item {
      width: 100%;
      max-width: 275px;

      @media (--sm) {
        max-width: 400px;
      }

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    &Lnk {
      height: 50px;
    }
  }
}
