@define-mixin borderRadiuses {
  &.-radius_sm {
    border-radius: 3px;
  }

  &.-radius_md {
    border-radius: 5px;
  }

  &.-radius_lg {
    border-radius: 10px;
  }
}
