@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes borderFadeIn {
  from {
    border-color: transparent;
  }

  to {
    border-color: var(--color-darkgray);
  }
}

@keyframes borderFadeOut {
  from {
    border-color: var(--color-darkgray);
  }

  to {
    border-color: transparent;
  }
}

@keyframes shadowFadeIn {
  from {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0);
  }

  to {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  }
}

@keyframes shadowFadeOut {
  from {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  }

  to {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0);
  }
}

@keyframes translateYIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes translateYOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}

@keyframes translateY20In {
  from {
    transform: translateY(20px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes translateY20Out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(20px);
  }
}

