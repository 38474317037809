.lower {
  &-main {
    display: flex;
    justify-content: center;
    padding: 20px 0 250px;

    @media (--sm) {
      padding: 90px 0 250px;
    }
  }

  &-cont {
    width: calc(100% - 50px);
    max-width: 1050px;
  }

  &-main-ttl {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 15px;

    @media (--sm) {
      font-size: 3.0rem;
      margin-bottom: 85px;
    }
  }
  &-desc {
    margin-bottom: 45px;

    @media (--sm-max) {
      font-size: 1.4rem;
    }
    @media (--sm) {
      margin-bottom: 28px;
    }
  }
  &-sec {
    margin-bottom: 20px;

    @media (--sm-max) {
      font-size: 1.4rem;
    }
  }
  &-ttl {
    margin-bottom: 5px;
  }
  &-ttl2 {
    font-weight: normal;
    margin-top: 30px;
  }
  &-txt {
  }
  &-last-txt {
  }
}

.lst-disc {
  margin-left: 1.5em;
  list-style-type: disc;
  &__item {}
  &__lnk {}
}

.lst-decimal {
  margin-left: 1.5em;
  list-style-type: decimal;

  @media (--sm) {
    margin-left: 2.0em;
  }
  &__item { }
}

.lst-decimal-b {
  counter-reset: decimal;
  margin-top: 20px;

  &__item {
    display: flex;

    &::before {
      min-width: 35px;
      width: 35px;
      counter-increment: decimal;
      content: "("counter(decimal)") ";
      text-align: center;
      @media (--sm) {
        min-width: 40px;
        width: 40px;
      }
    }
  }
}

.lower-mb-m {
  margin-bottom: 20px;
}
