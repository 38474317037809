.main {
  padding-top: 20px;
  padding-bottom: 50px;

  @media (--sm) {
    padding-top: 30px;
  }

  @media (--sm) {
    padding-bottom: 100px;
  }
}
