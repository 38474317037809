.glbFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 20px var(--color-gray);

  .snsNav {
    margin-bottom: 50px;

    @media (--sm) {
      display: none;
    }

    &__item {
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  & &Copyright {
    padding-bottom: 8px;

    @media (--md) {
      padding-bottom: 12px;
    }
  }
}
