.u-dn {
  &.-sm {
    @media (--sm) {
      display: none !important;
    }
  }

  &.-smMax {
    @media (--sm-max) {
      display: none !important;
    }
  }

  &.-md {
    @media (--md) {
      display: none !important;
    }
  }

  &.-mdMax {
    @media (--md-max) {
      display: none !important;
    }
  }
}
