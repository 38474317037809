.glbOverlay {
  width: 100%;
  height: 100vh;
  background-color: var(--color-white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transform: translateY(-100%);

  body.is-opened--drawerNav & {
    animation: translateYIn 0.5s ease forwards;
  }

  body.is-closed--drawerNav & {
    transform: translateY(0);
    animation: translateYOut 0.5s ease 0.5s forwards;
  }
}

