.u-fs {
  &.-xl {
    font-size: 2.4rem;
  }

  &.-lg {
    font-size: 2.0rem;
  }

  &.-md {
    font-size: 1.6rem;
  }

  &.-sm {
    font-size: 1.4rem;
  }

  &.-xs {
    font-size: 1.2rem;
  }
}
