.c-icon {

  &::before {
    display: block;
  }

  &.-chvron-right::before {
    content: url(/assets/img/icon--chevron-right.svg);
    width: 30px;
    height: 30px;
  }

  &.-chvron-up::before {
    content: url(/assets/img/icon--chevron-up.svg);
    width: 30px;
    height: 30px;
  }

  &.-arrow-right::before {
    content: url(/assets/img/icon--arrow-right.svg);
    width: 30px;
    height: 30px;
  }
}
