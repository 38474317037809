.c-parallax {
  position: relative;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url(/assets/img/home/parallax_bg.png);
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: 55%;
  }

  &__ornament,
  &__obj,
  &__logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
  }

  &__ornament {
    &.-tree {
      background-image: url(/assets/img/home/parallax_ornament--tree.png);
      transform: translateY(var(--translateY--tree));
      will-change: transform;
    }

    &.-grass {
      background-image: url(/assets/img/home/parallax_ornament--grass.png);
    }
  }

  &__obj {
    &.-kuma {
      background-image: url(/assets/img/home/parallax_obj--kuma.png);
      //visibility: hidden;
      transform: translateY(var(--translateY--kuma));
      opacity: var(--opacity--kuma);
      will-change: transform, opacity;
    }
  }

  &.-active &__obj.-kuma {
    visibility: visible;
  }

  &__logo {
    background-image: url(/assets/img/home/parallax_logo.png);
    transform: translateY(var(--translateY--logo));
    will-change: transform;
  }
}
